// Navtoggler
document.querySelector('.headerbar__navtoggler').addEventListener('click', function () {
    if (document.body.classList.contains('body--activenav')) {
        document.body.classList.remove('body--activenav');
    } else {
        document.body.classList.add('body--activenav');
    }
});

// Detect Firefox
if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
    document.querySelector('.headerbar').classList.add('firefoxclient');
}